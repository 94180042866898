<template>
  <div>
    <Navbar
      mode="solid"
      class="navbar"
      :show-btn-icon="true"
      btn-link-path="/account"
    />
    <main class="pb-32 mt-20">
      <div class="wrapper">
        <h1 class="title" v-t="'page.account.tile.personal_info.title'"/>
        <account-profile></account-profile>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import AccountProfile from "./AccountProfile.vue";

export default {
  name: "Account",
  components: {
    AccountProfile,
  },
  data() {
    return {
      dataTabSections: [
        {
          name: this.$t("data.tabs.profile"),
          isActive: true,
          textColor: "text-black-base",
        },
        {
          name: this.$t("data.tabs.password"),
          isActive: false,
          textColor: "text-grey-dark",
        },
      ],
      crumbs: [
        { path: "/account", text: this.$t("data.breadcrumbs.account") },
        { path: "/account/data", text: this.$t("data.breadcrumbs.data") },
      ],
    };
  },
};
</script>
<style scoped>
.title {
  @apply mb-8 font-sans text-2xl font-bold text-black-base;
}
.wrapper {
  @apply relative w-full px-4 pt-12 transform -translate-x-1/2 max-w-screen-sm left-1/2;
}

.navbar {
  @apply fixed top-0 z-20 w-full border-none;
}
</style>