<template>
  <section class="profile">
    <div>
        <Avatar size="xl" color="teal" :img="profileImg"/>
      <div class="mt-3">
        <label v-if="isEditMode" class="block mb-3" for="image">
          <input
            id="image"
            class="block left-96 overflow-hidden"
            name="image"
            type="file"
            @change="onFileChange($event)"
          />
        </label>
        <div v-if="!isEditMode">
          <ButtonPrimary
            :text="$t('cta.upload_new_picture')"
            class="px-3"
            @click.native="toggleEditMode()"
          />
          <ButtonBasic 
            v-if="false"
            class="inline-block ml-3 py-2.5 px-5" 
            :text="$t('cta.delete')" 
            @click.native="deleteImg()" 
          />
        </div>
        <div v-if="isEditMode">
          <ButtonPrimary
            :text="$t('cta.upload_now')"
            class="px-3"
            :loading="loading"
            @click.native="updateProfile()"
          />
          <ButtonBasic
            class="inline-block ml-3 py-2.5 px-5"
            :text="$t('cta.cancel')"
            @click.native="toggleEditMode()"
          />
        </div>
      </div>
    </div>

    <form @submit.prevent="updateProfile">
      <DataTile
        :valid="valid"
        :loading="loading"
        :title="$t('page.personal_info.name.label')"
        :value="profile.firstName + ' ' + profile.lastName"
      >
        <div class="input-container">
          <InputText
            v-model="profile.firstName"
            :errorMsg="firstNameErrMsg"
            :label="$t('data.profile.first_name.label')"
            :placeholder="$t('data.profile.first_name.placeholder')"
            :showErrMsg="$v.profile.firstName.$error"
            class="items-start w-full sm:w-calc-1/2"
            name="first-name"
            type="text"
            @blur="$v.profile.firstName.$touch()"
          />
          <InputText
            v-model="profile.lastName"
            :errorMsg="lastNameErrMsg"
            :label="$t('data.profile.last_name.label')"
            :placeholder="$t('data.profile.first_name.placeholder')"
            :showErrMsg="$v.profile.lastName.$error"
            class="items-start w-full sm:w-calc-1/2"
            name="last-name"
            @blur="$v.profile.lastName.$touch()"
          />
        </div>
      </DataTile>
      <DataTile 
        :valid="valid"
        :title="$t('page.personal_info.email.label')" :value="profile.email" 
        :loading="loading">
        <InputText
          v-model="profile.email"
          :errorMsg="emailErrMsg"
          :label="$t('data.profile.email.label')"
          :placeholder="$t('data.profile.email.placeholder')"
          :showErrMsg="$v.profile.email.$error"
          class="w-full"
          name="email"
          @blur="$v.profile.email.$touch()"
        />
      </DataTile>
      <DataTile 
      
        :valid="valid"
        :loading="loading" :title="$t('page.personal_info.phone.label')" :value="profile.phone">
        <InputPhone
          v-model="profile.phone"
          :phoneNumber="profile.phone"
          @select="updateNumber"
          @input="updateNumber"
        />
      </DataTile>
    </form>
  </section>
</template>

<script>
import InputPhone from "@/components/inputs/InputPhone";
import { mapActions, mapGetters } from "vuex";
import DataTile from "@/components/tiles/DataTile";
import ButtonBasic from "@/components/buttons/ButtonBasic";
import Avatar from "@/components/Avatar.vue";

const {
  required,
  minLength,
  email,
  // numeric,
} = require("vuelidate/lib/validators");

export default {
  name: "account-profile",
  components: { ButtonBasic, DataTile, InputPhone, Avatar },
  watch: {
    valid(newValue) {
      if (newValue === true) {
        this.isEditMode = false;
      }
    }
  },
  data() {
    return {
      country: "",
      profile: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        image: "",
      },
      valid: false,
      profileImg: "",
      loading: false,
      state:false,
      isEditMode: false,
    };
  },
  validations: {
    profile: {
      firstName: {
        required,
        minLength: minLength(2),
      },
      lastName: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    ...mapGetters("user", ["user"]),
    firstNameErrMsg() {
      let msg;
      if (!this.$v.profile.firstName.required) {
        msg = this.$t("data.profile.first_name.error_1"); //First Name is required
      }
      if (!this.$v.profile.firstName.minLength) {
        msg = this.$tc("data.profile.first_name.error_2", 2); //First name must have at least {count} characters
      }
      /*if (!this.$v.profile.firstName.isNameValid) {
        msg = this.$t("data.profile.first_name.error_2"); //First name must only contain alphabetic characters
      }*/
      return msg;
    },
    lastNameErrMsg() {
      let msg;
      if (!this.$v.profile.lastName.required) {
        msg = this.$t("data.profile.last_name.error_1"); //Last Name is required
      }
      if (!this.$v.profile.lastName.minLength) {
        msg = this.$tc("data.profile.last_name.error_2", 2); //Last name must have at least {count} characters
      }
      /*if (!this.$v.profile.lastName.isNameValid) {
        msg = this.$t("data.profile.last_name.error_2"); //Last name must only contain alphabetic characters
      }*/
      return msg;
    },
    emailErrMsg() {
      let msg;
      if (!this.$v.profile.email.email) {
        msg = this.$t("common.error.please_enter_a_valid_mail");
      }
      if (!this.$v.profile.email.required) {
        msg = this.$t("common.error.email_required");
      }
      return msg;
    },
  },
  methods: {
    ...mapActions("user", ["getUserProfile", "updateUserProfile"]),
    toggleEditMode() {
      this.isEditMode = !this.isEditMode;
    },
    onFileChange(e) {
      this.profile.image = e.target.files[0];
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      const reader = new FileReader();
      const vm = this;
      reader.onload = (e) => {
        vm.profileImg = e.target.result;
      };
      return reader.readAsDataURL(file);
    },
    isFile(input) {
      return "File" in window && input instanceof File;
    },
    updateNumber(phoneNumber) {
      this.profile.phone = phoneNumber;
    },
    async deleteImg() {
      try {
        this.$v.profile.$touch();
        if (this.$v.profile.$invalid === false) {
          this.profile.image = "";
          const data = {
            ...this.profile,
            ...(this.profile.phone
              ? { phone: `${this.profile.phone}` }
              : { phone: null }),
          };

          this.loading = true;

          const res = await this.updateUserProfile(data);
          if (!res) return false;
          this.loading = false;
          this.$notify({
            type: "success",
            title: res.data.message,
          });
          this.valid = true;
          this.getUserProfile();
        }

      } catch (error) {
        this.loading = false;
        if (error.message === "Duplicate entry") {
          return this.$notify({
            type: "error",
            title: "Email is already used by another user",
          });
        }
        this.$notify({
          type: "error",
          title: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async updateProfile() {
      try {
        this.$v.profile.$touch();
        if (this.$v.profile.$invalid === false) {
          if (!this.isFile(this.profile.image)) {
            delete this.profile.image;
          }

          const data = {
            ...this.profile,
            ...(this.profile.phone
              ? { phone: `${this.profile.phone}` }
              : { phone: null }),
          };

          this.loading = true;

          const res = await this.updateUserProfile(data);
          if (!res) return false;
          this.loading = false;
          this.$notify({
            type: "success",
            title: res.data.message,
          });
          this.valid = true;

          this.getUserProfile();
        }

        
      } catch (error) {
        this.loading = false;
        if (error.message === "Duplicate entry") {
          return this.$notify({
            type: "error",
            title: "Email is already used by another user",
          });
        }
        this.$notify({
          type: "error",
          title: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  beforeMount() {
    this.profile.phone = this.user.phone;
  },
  async created() {
    this.profile = {
      email: this.user.email,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      ...(!this.user.phone
        ? { phone: null }
        : this.user.phone.split(" ").length > 1
        ? { phone: this.user.phone.split(" ")[1] }
        : { phone: this.user.phone.split(" ")[0] }),

      dialCode: (this.user.phone && this.user.phone.slice(0, 2)) || "AF",
      image:
        (this.user.image &&
          `${process.env.VUE_APP_FILE_BASE_PATH}${this.user.image}`) ||
        "",
    };

    this.profileImg =
      this.user.image &&
      `${process.env.VUE_APP_FILE_BASE_PATH}${this.user.image}`;

  },
};
</script>

<style scoped>
.profile-avatar {
  @apply relative w-24 h-24 mt-20 overflow-hidden;
  @apply rounded-full bg-violet-lighter;
}

.input-container {
  @apply flex flex-wrap items-start justify-between;
}

.input-container .input-text {
  @apply mb-4 sm:mb-0;
}
</style>
