var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    _vm.textColor,
    _vm.hoverBackground,
    'uppercase',
    'font-bold',
    'rounded',
    'transition-all',
    'px-3',
    'py-2',
    'duration-300',
    'focus:outline-none focus:scale-95 focus:ring-offset-2 focus:ring-2 focus:border-teal-base focus:ring-grey-base',
  ],attrs:{"type":_vm.type},on:{"click":function($event){return _vm.$emit('textClick')}}},[_vm._v(" "+_vm._s(_vm.text)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }