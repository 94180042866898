<template>
  <div class="user-text-row">
    <div class="user-text-container">
      <div class="w-full flex justify-between">
        <p v-if="showEdit" class="user-text-label">{{ title }}</p>
        <ButtonText
          @click.native="toggleTile"
          v-if="showEdit"
          class="self-start ml-auto"
          :text="$t('cta.edit')"
        />
        <ButtonText
          @click.native="toggleTile"
          v-if="showCancel"
          class="self-start ml-auto"
          :text="$t('cta.cancel')"
        />
      </div>
      <p v-if="showEdit" class="user-text-value">{{ value }}</p>
    </div>
    <div v-if="showCancel" class="user-text-container">
      <div class="input-container flex flex-wrap">
        <slot @click="$emit('test')"></slot>
        <InputText v-if="false" :class="inputStyle" :value="value" :label="firstLabel" />
        <InputText
            v-if="false"
          :label="secondLabel"
          class="w-full s:w-calc-1/2 ml-auto"
          :value="value"
        />
      </div>
      <ButtonPrimary :text="$t('cta.save')" class="save-btn" :loading="loading"/>
    </div>
  </div>
</template>
<script>
import ButtonPrimary from "@/components/buttons/ButtonPrimary";
import ButtonText from "@/components/buttons/ButtonText";
import InputText from "@/components/inputs/InputText";
export default {
  name: "DataTile",
  components: { ButtonPrimary, ButtonText, InputText },
  data() {
    return {
      showCancel: false,
      showEdit: true,
    };
  },
  props: {
    title: {
      type: String,
      default: "Title",
    },
    value: {
      type: String,
      default: "Value",
    },
    secondInput: {
      type: Boolean,
      default: false,
    },
    firstLabel: {
      type: String,
      default: "First label",
    },
    secondLabel: {
      type: String,
      default: "Second label",
    },
    valid: {
      type: Boolean,
      default:false
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputStyle() {
      if (this.secondInput) {
        return "s:w-calc-1/2 s:mb-initial w-full mb-3 ";
      }

      return "w-full";
    },
  },
  watch: {
    valid(newValue) {
      if (newValue === true) {
        this.showCancel = false;
        this.showEdit = true;
      }
    }
  },
  methods: {
    toggleTile() {
      this.showCancel = !this.showCancel;
      this.showEdit = !this.showEdit;
    },
  },
};
</script>
<style scoped>
.user-text-container {
  @apply w-full;
}
.user-text-row {
  @apply py-6 border-b border-grey-base flex justify-between flex-wrap;
}

.user-text-label {
  @apply font-sans text-black-lightest;
}

.user-text-value {
  @apply font-sans text-black-base font-semibold;
}

.input-container {
  @apply mt-6;
}

.save-btn{
  @apply px-12 mt-4 w-full xs:w-auto;
}
</style>