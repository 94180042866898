<template>
  <button
    :type="type"
    @click="$emit('textClick')"
    :class="[
      textColor,
      hoverBackground,
      'uppercase',
      'font-bold',
      'rounded',
      'transition-all',
      'px-3',
      'py-2',
      'duration-300',
      'focus:outline-none focus:scale-95 focus:ring-offset-2 focus:ring-2 focus:border-teal-base focus:ring-grey-base',
    ]"
  >
    {{ text }}
  </button>
</template>
<script>
export default {
  name: "ButtonText",
  props: {
    type: {
      type: String,
      default: "button",
    },
    text: {
      type: String,
      default: "Button",
    },
    textColor: {
      type: String,
      default: "text-teal-base",
    },
    hoverBackground: {
      type: String,
      default: "hover:bg-teal-lighter",
    },
  },
};
</script>